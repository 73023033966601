import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactGlobal from "components/ContactGlobal"

import { Hero, Why, Baner, Numbers } from "page_components/clients-financing"

const Developers = () => {
  const title = "Finansowanie"

  return (
    <Layout
      seo={{
        title: title,
        description:
          "Skorzystaj z pomocy doświadczonego eksperta i wybierz najbardziej optymalną formę finansowania. Umów się na bezpłatne spotkanie z doradcą.",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Dla klientów",
          path: "/dla-klientow/",
        }}
      />
      <PageHeader title={title} />
      <Hero />
      <Why />
      <Baner />
      <Numbers />
      <ContactGlobal formFinancing />
    </Layout>
  )
}

export default Developers
