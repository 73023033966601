import "./styles.scss"

import React from "react"

const Baner = () => {
  return (
    <section className="clients-financing-baner">
      <div className="container-fluid">
        <p>
          O szczegóły zapytaj Twojego opiekuna Marshall Real Estate – przedstawi
          kolejne kroki i przeprowadzi przez cały proces przybliżając Cię do
          uzyskania finansowania na wymarzoną nieruchomość.
        </p>
      </div>
    </section>
  )
}

export default Baner
