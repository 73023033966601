import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Why = () => {
  const data = [
    {
      icon: require("assets/images/clients/clients-financing-why-icon-1.svg")
        .default,
      header: "Oferta kilkunastu banków w jednym miejscu",
      description:
        "Współpracujący z nami doradcy reprezentują największą firmę pośrednictwa finansowego w Polsce. W ich portfolio można znaleźć oferty kilkunastu banków co pozwala na najlepszy dobór finansowania do potrzeb Klienta.",
    },
    {
      icon: require("assets/images/clients/clients-financing-why-icon-2.svg")
        .default,
      header: "Wszystko w jednym miejscu",
      description:
        "Oprócz szerokiej gamy apartamentów, przygotowaliśmy również bogatą ofertę usług dodatkowych. Wykończenie nieruchomości pod klucz, profesjonalna obsługa najmu czy pomoc w odzyskaniu podatku VAT. A może potrzebujesz wsparcia w pozyskania finansowania bankowego? Jesteśmy tutaj dla Ciebie.",
    },
    {
      icon: require("assets/images/clients/clients-financing-why-icon-3.svg")
        .default,
      header: "Uczciwość i transparentność",
      description:
        "Współpracujący z nami eksperci nie są powiązany z żadną instytucją bankową. Zawsze prezentują plusy i minusy wybranej przez Klienta oferty kredytowej. Rozmawiają o ryzykach kredytu i rekomendują najlepsze rozwiązania.",
    },
    {
      icon: require("assets/images/clients/clients-financing-why-icon-4.svg")
        .default,
      header: "Wieloletnie doświadczenie",
      description:
        "Każdy z ekspertów posiada wieloletnie doświadczenia na rynku kredytów mieszkaniowych, gotówkowych oraz firmowych.",
    },
    {
      icon: require("assets/images/clients/clients-financing-why-icon-5.svg")
        .default,
      header: "Porównywanie ofert",
      description:
        "Jeśli Klient posiada już ofertę kredytu, ma możliwość przedstawienia jej ekspertowi. Pozwoli to na weryfikację atrakcyjności propozycji. Warto pamiętać, że oferty banków szybko się zmieniają i często pojawiają się nowe promocje.",
    },
    {
      icon: require("assets/images/clients/clients-financing-why-icon-6.svg")
        .default,
      header: "Darmowe spotkanie",
      description:
        "Wynagrodzenie eksperta jest pokrywane przez instytucje bankowe. Nie oznacza to jednak, że finansowanie uzyskane za jego pośrednictwem będzie droższe niż w banku.",
    },
  ]

  return (
    <section className="clients-financing-why">
      <div className="container-fluid">
        <h2>
          Dlaczego Klienci wybierają rekomendowanych przez nas Ekspertów
          Finansowych?
        </h2>
        <div className="row">
          {data.map((item, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="clients-financing-why__item">
                <div className="clients-financing-why__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h4>{item.header}</h4>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Why
