import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Hero = () => {
  return (
    <section className="clients-financing-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Umów się na bezpłatne spotkanie z doradcą</h2>
            <p>
              Zrealizuj swoje marzenie o posiadaniu nieruchomości w miejscowości
              atrakcyjnej turystycznie. Skorzystaj z pomocy doświadczonego
              eksperta i wybierz najbardziej optymalną formę finansowania.
            </p>
            <span>
              Wspieramy w uzyskaniu finansowania na nieruchomości objęte 8% i
              23% stawką VAT
            </span>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="clients-financing-hero__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/clients/clients-financing-hero-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
