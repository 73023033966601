import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Numbers = () => {
  const data = [
    {
      number: "22",
      header: "lata doświadczenia",
      description: "Pomagamy klientom w realizacji ich planów i marzeń.",
    },
    {
      number: "13",
      header: "banków",
      description: "Dopasujesz najlepsze warunki kredytu",
    },
    {
      number: "17,44 <span>mld</span>",
      header: "kredyty hipoteczne",
      description: "Uruchomione w bankach w 2021 r.",
    },
    {
      number: "0 <span>zł</span>",
      header: "prowizji",
      description: "Ty nic nie płacisz, to koszty ponoszą banki",
    },
  ]

  return (
    <section className="clients-financing-numbers">
      <div className="container-fluid">
        <h2>Warto współpracować z najlepszym</h2>
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              {data.map((item, index) => (
                <div className="col-6" key={index}>
                  <div className="clients-financing-numbers__item">
                    <div dangerouslySetInnerHTML={{ __html: item.number }} />
                    <h4>{item.header}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-4">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/clients/clients-financing-numbers-image.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Numbers
